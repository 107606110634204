@use "@carbon/react/scss/colors" as *;
@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/motion" as *;
@use "@carbon/react/scss/theme" as *;
@use "@carbon/react/scss/themes" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;

.wrapper {
  background-color: $layer-01;
  padding: $spacing-07;
}

.container {
  max-width: $spacing-13 * 4;
  margin: 0 auto;
}

.title {
  @include type-style("productive-heading-04");
  color: $text-primary;
  margin-bottom: $spacing-06;
}

.formContainer {
  width: 100%;
}

.inputGroup {
  display: flex;
  gap: $spacing-05;
  margin-bottom: $spacing-05;
}

.message {
  margin-top: $spacing-05;
  @include type-style("body-short-01");

  &Success {
    color: $support-success;
    display: flex;
    align-items: center;
    gap: $spacing-03;
  }

  &Error {
    color: $support-error;
    display: flex;
    align-items: center;
    gap: $spacing-03;
  }

  &Info {
    color: $text-secondary;

    a {
      color: $link-primary;
      text-decoration: none;

      &:hover {
        color: $link-primary-hover;
      }
    }
  }
}
