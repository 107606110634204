@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/theme" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;

//---------------------------------------
// P
//---------------------------------------

.paragraph {
  @include type-style("body-long-02");
  padding-left: var(--cds-spacing-05);
  padding-right: var(--cds-spacing-05);

  @include breakpoint("md") {
    padding-right: var(--cds-spacing-07);
  }
}

// Responsive by default
.paragraph--responsive {

  // 8 col
  @include breakpoint("md") {
    width: 75%;
  }

  // 8 col
  @include breakpoint("lg") {
    width: 66.667%;
  }
}

//---------------------------------------
// Lists OL and UL
//---------------------------------------

ol.ordered {
  margin-left: var(--cds-spacing-06);
  width: calc(100% - var(--cds-spacing-05));

  @include breakpoint("md") {
    width: calc(75% - var(--cds-spacing-07));
  }

  @include breakpoint("lg") {
    width: calc(66.667% - var(--cds-spacing-07));
  }
}

ul.unordered {
  width: calc(100% - var(--cds-spacing-05));

  @include breakpoint("md") {
    width: calc(75% - var(--cds-spacing-07));
  }

  @include breakpoint("lg") {
    width: calc(66.667% - var(--cds-spacing-07));
  }
}

//---------------------------------------
// Responsive widths
//---------------------------------------

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  padding-right: var(--cds-spacing-05);
  margin-right: 0;
  margin-left: 0;

  @include breakpoint("md") {
    width: 75%;
    padding-right: var(--cds-spacing-07);
    margin-right: 0;
    margin-left: 0;
  }

  @include breakpoint("lg") {
    width: 66.667%;
  }
}

.h4+* {
  --space: 0;
}

//---------------------------------------
// Blockquote
//---------------------------------------
div {
  .blockquote {
    margin: var(--cds-spacing-08) 0;
    color: var(--cds-text-01);
    margin-left: var(--cds-spacing-05);
    padding-left: 1ch;

    @include breakpoint("md") {
      margin-left: var(--cds-spacing-08);
    }
  }

  .blockquote .paragraph {
    position: relative;
    @include type-style("expressive-heading-03", true);
    font-style: italic;
    margin-bottom: 0;
    width: 100%;
  }

  .blockquote .paragraph--responsive {

    // 8 col
    @include breakpoint("lg") {
      width: calc(66.667% - 3rem);
    }
  }
}

// If quote is inside a user specified row then allow the grid code to set the width
:global(.cds--row) .blockquote .paragraph {
  @include breakpoint("md") {
    width: 100%;
  }
}

.blockquote cite {
  @include type-style("body-long-01");
  font-style: italic;
  display: block;
  margin-top: var(--cds-spacing-02);
  text-indent: 0;
}

// If paragraph is inside a user specified row then allow the grid code to set the width and padding
:global(.cds--row .cds--row) .paragraph {
  width: 100%;
  padding-left: 0;

  @include breakpoint("md") {
    padding-right: 0;
  }
}

//---------------------------------------
// Headings
//---------------------------------------

.h1 {
  --space: #{var(--cds-spacing-12)};
  margin-top: var(--space);
  @include type-style("expressive-heading-05", true);
}

.h2,
h2.h2 {
  --space: #{var(--cds-spacing-11)};
  margin-top: var(--space);
  @include type-style("expressive-heading-04", true);
}

.h3 {
  --space: #{var(--cds-spacing-10)};
  margin-top: var(--space);
  @include type-style("expressive-heading-03", true);
}

.h4 {
  --space: #{var(--cds-spacing-07)};
  margin-top: var(--space);
  @include type-style("heading-02");
}

.h5,
.h6 {
  --space: 0;
  @include type-style("heading-01");
}

.sup {
  font-feature-settings: "sups" on;
}

.abbr:global(.cds--tooltip--definition) button {
  font-size: 1em;
}
