@use "@carbon/react/scss/colors" as *;
@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/theme" as *;
@use "@carbon/react/scss/compat/theme" as *;
@use "@carbon/react/scss/motion" as *;
@use "@carbon/react/scss/breakpoint" as *;

.article {
  background-color: $text-inverse;
  padding: $spacing-02 $spacing-07;
}
