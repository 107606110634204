@use "@carbon/react/scss/colors" as *;
@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/motion" as *;
@use "@carbon/react/scss/theme" as *;
@use "@carbon/react/scss/themes" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;

.metadata {
  padding: $spacing-04 0;
}

.metadataContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: $spacing-03;
}

.metaGroup {
  display: flex;
  gap: $spacing-05;
  align-items: center;
}

.metaItem {
  display: flex;
  align-items: center;
  gap: $spacing-02;
  color: $text-secondary;
  @include type-style("body-compact-01");

  .icon {
    color: $icon-secondary;
  }
}

.tags {
  display: flex;
  gap: $spacing-02;
  flex-wrap: wrap;
}
