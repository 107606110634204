@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/motion" as *;
@use "@carbon/react/scss/theme" as *;
@use "@carbon/react/scss/themes" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/breakpoint" as *;

$link-01: #3d70b2;

.header {
  position: relative;
}

.anchor {
  opacity: 0;
  transition: opacity $duration-fast-01 $standard-easing;

  &:hover,
  &:active,
  &:focus {
    opacity: 1;
  }

  &:focus {
    outline: 2px solid $focus;
  }

  svg {
    fill: $link-01;
  }
}

.leftAnchor {
  position: absolute;
  left: -$spacing-06;
  top: 0;
}

.rightAnchor {
  white-space: nowrap;
  margin-left: $spacing-03;
}

@include breakpoint("md") {
  .header:hover .anchor {
    opacity: 1;
  }
}
